import React from "react";
import { Link } from "gatsby";
import iconShoppingCart from "../../../static/images/ico-shoppingcart.png"
//import { Trans } from "@lingui/macro";
import "./ProductList.css"

const ProductList = ({title, locale, items}) => {
  return (
      <div className="ProductList">
          {items.map((node) =>
              <div className="product" key={node.id}>
                  <Link to={"/sk" + node.frontmatter.uri}>
                      <div className="img">
                          <img src={node.frontmatter.gallery[0].image.small.fluid.src} alt=""/>
                      </div>
                      <h2>{node.frontmatter.title}</h2>
                      <p className="price">
                          <span className="priceVat">{node.frontmatter.priceVat[0]}&euro; s DPH za {node.frontmatter.unit}</span>
                          <span className="priceNoVat">{node.frontmatter.priceNoVat[0]}&euro; bez DPH za {node.frontmatter.unit}</span>
                      </p>
                      <p className="cart"><img src={iconShoppingCart} alt="Vložiť do košíka"/></p>
                  </Link>
              </div>
          )}
      </div>
  );
};

export default ProductList;
